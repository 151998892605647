@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "PoppinsLight";
    src: local("Poppins"), url(./assets/fonts/poppins/Poppins-Light.ttf) format("truetype");
}
@font-face {
    font-family: "PoppinsRegular";
    src: local("Poppins"), url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "PoppinsMedium";
    src: local("Poppins"), url(./assets/fonts/poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
    font-family: "PoppinsBold";
    src: local("Poppins"), url(./assets/fonts/poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "OswaldRegular";
  src: local("Oswald"), url(./assets/fonts/oswald/Oswald-Regular.ttf) format("truetype");
}


@import "assets/fonts/poppins/icons-font/fonts.scss";

html,
body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}
body {
    margin: 0;
}
* {
    box-sizing: border-box !important;
}
em {
    font-weight: bold !important;
    font-style: normal !important;
}

.fadeIn {
    animation: ml 0.5s ease-in-out 0s forwards;
    -webkit-animation: ml 0.5s ease-in-out 0s forwards;
    opacity: 0;
}

@keyframes ml {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.mat-dialog-container {
    padding: 0 !important;
    // background-color: transparent !important;
    overflow: visible !important;
}

.cdk-overlay-pane {
    max-width: 100vw !important;
}

.mat-expansion-indicator::after {
    border-color: black !important;
}

.leaflet-touch .leaflet-control-attribution {
    display: none !important;
}

.custom-backdrop {
    background: #181818c7;
}

.pac-item {
    font-size: medium !important;
    font-family: "PoppinsRegular" !important;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.pac-item-query {
    font-size: medium !important;
    font-family: "PoppinsRegular" !important;
}

.pac-container::after {
    content: "" !important;
    display: none !important;
}
