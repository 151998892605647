@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?7xdpj6');
  src:  url('icomoon.eot?7xdpj6#iefix') format('embedded-opentype'),
    url('icomoon.ttf?7xdpj6') format('truetype'),
    url('icomoon.woff?7xdpj6') format('woff'),
    url('icomoon.svg?7xdpj6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-about-24px-1:before {
  content: "\e928";
}
.icon-add-round-24px-1:before {
  content: "\e929";
}
.icon-busniss-24px-1:before {
  content: "\e92a";
}
.icon-cached-round-24px-1:before {
  content: "\e92b";
}
.icon-divisor-round-24px-1:before {
  content: "\e92c";
}
.icon-edit-round-24px-1:before {
  content: "\e92d";
}
.icon-event-outlined-24px-1:before {
  content: "\e92e";
}
.icon-file-outlined-24px-1:before {
  content: "\e92f";
}
.icon-help-outline-round-24px-1:before {
  content: "\e930";
}
.icon-history-round-24px-1:before {
  content: "\e931";
}
.icon-payment-round-24px-1:before {
  content: "\e932";
}
.icon-signature:before {
  content: "\e933";
}
.icon-slash-round-24px-1:before {
  content: "\e934";
}
.icon-support-agent-round-24px-1:before {
  content: "\e935";
}
.icon-arrow-back-round-24px-2:before {
  content: "\e900";
}
.icon-arrow-forward-round-24px-2:before {
  content: "\e901";
}
.icon-attach-money-round-24px-1:before {
  content: "\e902";
}
.icon-camera-alt-round-24px-1:before {
  content: "\e903";
}
.icon-check-box-outline-blank-round-24px-2:before {
  content: "\e904";
}
.icon-check-box-round-24px-2:before {
  content: "\e905";
}
.icon-close-round-24px-2:before {
  content: "\e906";
}
.icon-date-range-round-24px-2:before {
  content: "\e907";
}
.icon-delete-outline-round-24px-2:before {
  content: "\e908";
}
.icon-delete-outline-round-24px-3:before {
  content: "\e909";
}
.icon-favorite-border-round-24px-2:before {
  content: "\e90a";
}
.icon-favorite-round-24px-2:before {
  content: "\e90b";
}
.icon-filter-list-round-24px-2:before {
  content: "\e90c";
}
.icon-food-truck-round-24px-1:before {
  content: "\e90d";
}
.icon-indeterminate-check-box-round-24px-2:before {
  content: "\e90e";
}
.icon-keyboard-arrow-down-round-24px-2:before {
  content: "\e90f";
}
.icon-keyboard-arrow-left-round-24px-2:before {
  content: "\e910";
}
.icon-keyboard-arrow-right-round-24px-2:before {
  content: "\e911";
}
.icon-keyboard-arrow-up-round-24px-2:before {
  content: "\e912";
}
.icon-local-dining-round-24px-2:before {
  content: "\e913";
}
.icon-lock-outline-round-24px-1:before {
  content: "\e914";
}
.icon-mail-outline-round-24px-1-1:before {
  content: "\e915";
}
.icon-map-round-24px-2:before {
  content: "\e916";
}
.icon-menu-round-24px-2:before {
  content: "\e917";
}
.icon-navigation-round-24px-2:before {
  content: "\e918";
}
.icon-people-outline-round-24px-1:before {
  content: "\e919";
}
.icon-person-outline-round-24px-1:before {
  content: "\e91a";
}
.icon-phone_outline-round-24px-1px:before {
  content: "\e91b";
}
.icon-pin-drop-round-24px-2:before {
  content: "\e91c";
}
.icon-radio-button-checked-round-24px-2:before {
  content: "\e91d";
}
.icon-radio-button-unchecked-round-24px-2:before {
  content: "\e91e";
}
.icon-search-round-24px-1:before {
  content: "\e91f";
}
.icon-share-round-24px-2:before {
  content: "\e920";
}
.icon-star-half-round-24px-2:before {
  content: "\e921";
}
.icon-tag-offer-round-24px-2:before {
  content: "\e922";
}
.icon-toggle-off-round-24px-1:before {
  content: "\e923";
}
.icon-toggle-on-round-24px-1:before {
  content: "\e924";
  color: #ce1351;
}
.icon-visibility-off-round-24px-1:before {
  content: "\e925";
}
.icon-visibility-round-24px-1:before {
  content: "\e926";
}
.icon-watch-later-round-24px-1:before {
  content: "\e927";
}
